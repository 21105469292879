import { bufferToAudioBlob, initSocket } from "lib/audio";
import React, { useEffect, useState, useRef } from "react";
import { RecordingState } from "lib/types";
import * as urls from "../url";
import "../App.css";
import TextComponent from "./common/TextComponent";

import { getContext, getAudioProcessor } from "../lib/audio";
import AudioDownloadButton from "./common/AudioDownloadButton";

function concatenate(
  resultConstructor: any,
  ...arrays: Float32Array[] | Float64Array[]
) {
  let totalLength = 0;
  for (const arr of arrays) {
    totalLength += arr.length;
  }
  const result = new resultConstructor(totalLength);
  let offset = 0;
  for (const arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
}

export default function StreamAudio() {
  const [streamState, setStreamState] = useState<RecordingState>({
    recording: false,
    socket_connected: false,
  });

  const [recordFinished, setRecordFinished] = useState<boolean>(false);

  const socket = useRef<WebSocket | null>(null);
  const totalData = useRef<Float32Array>(Float32Array.of());

  const initSocketInside = () => {
    socket.current = initSocket(urls.WS_URI);
    socket.current!.onclose = (msg: CloseEvent) => {
      setStreamState({
        recording: false,
        socket_connected: false,
      });
      setTimeout(() => {
        if (
          socket.current!.readyState !== WebSocket.OPEN &&
          socket.current!.readyState !== WebSocket.CONNECTING
        ) {
          initSocketInside();
        }
      }, 1000);
    };
    socket.current!.onopen = () => {
      setStreamState({
        recording: false,
        socket_connected: true,
      });
    };
  };

  useEffect(() => {
    initSocketInside();
  }, []);

  var buffer = Float32Array.of();
  const processor = useRef<ScriptProcessorNode | null>(null);
  const stream = useRef<MediaStream | null>(null);

  const transcribe = (data: Float32Array, callback: Function) => {
    socket.current!.onmessage = function (event) {
      callback(event.data);
    };
    totalData.current = concatenate(Float32Array, totalData.current, data);
    buffer = concatenate(Float32Array, buffer, data);

    let dataLength = buffer.length;
    let sendData = buffer.slice(0, dataLength);
    buffer = buffer.slice(dataLength);

    const int16Array = Int16Array.from(sendData, (x) => x * 32767);
    socket.current!.send(int16Array);
  };

  const startRecording = async () => {
    totalData.current = Float32Array.of();

    setRecordFinished(false);

    const onAudioProcess = (event: any) => {
      const data = event.inputBuffer.getChannelData(0);
      transcribe(data, (transcript: string) => {
        document.getElementById("transcript")!.innerText = transcript;
      });
    };

    const onMicrophoneCaptured = (stream: MediaStream) => {
      const context = getContext();
      const source = context.createMediaStreamSource(stream);
      processor.current = getAudioProcessor(context);
      source.connect(processor.current!);
      processor.current!.connect(context.destination);
      processor.current!.onaudioprocess = onAudioProcess;
    };

    const onMicrophoneError = (err: any) => {
      console.error(err);
    };

    stream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    try {
      onMicrophoneCaptured(stream.current);
    } catch (err) {
      console.error(err);
    }
  };

  const stopRecording = async () => {
    // source.current!.disconnect();
    processor.current!.onaudioprocess = null;
    processor.current!.disconnect();
    stream.current!.getTracks().forEach((track) => {
      track.stop();
    });
    socket.current!.close();
    buffer = Float32Array.of();
    setStreamState({
      recording: false,
      socket_connected: false,
    });
    initSocketInside();
    setRecordFinished(true);
  };

  const handleClickRecordStream = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!streamState.socket_connected) return;
    if (streamState.recording) {
      stopRecording();
    } else {
      startRecording();
      setStreamState((streamState) => ({
        ...streamState,
        recording: !streamState.recording,
      }));
    }
  };

  const downloadAudio = () => {
    let blob = bufferToAudioBlob(totalData.current);
    var url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = "databnk-record.wav";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const btnClass = "btn btn-small btn-outline-primary record-btn";
  const disabledBtnClass = btnClass + " disabled";
  const recordingClass = "recordIcon glyphicon glyphicon-record";
  const recordingClassRecording = recordingClass + " recording";
  return (
    <div className="container">
      <h2 className="category">Real-Time Recognition</h2>
      <div className="box">
        <div className="meta">
          <button
            className={
              streamState.socket_connected ? btnClass : disabledBtnClass
            }
            onClick={handleClickRecordStream}
            id="record"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-record-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            </svg>
            {streamState.recording ? " Stop" : " Start"} Recording
          </button>
          {recordFinished && <AudioDownloadButton onClick={downloadAudio} />}
        </div>
        <TextComponent id="transcript" />
      </div>

      <hr />
    </div>
  );
}
