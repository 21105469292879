import React from "react";

export default function Guide() {
  return (
    <div className="container">
      <div className="category">
        Guide
        <ol className="list-group list-group-numbered guide-list">
          <li className="list-group-item">
            Supports WAV, FLAC, and MP3 types of audio
          </li>
          <li className="list-group-item">
            WAV type, mono channel are recommended
          </li>
          <li className="list-group-item">Set Sample Rate 16000 of audio</li>
          <li className="list-group-item">
            <a
              href="https://www.aconvert.com/audio/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-primary"
            >
              Chage Sample Rate
            </a>
          </li>
          <li className="list-group-item">
            If it doesn't work, please refresh and use it
          </li>
          <li className="list-group-item">
            After the process of speech recognition, you can download the
            results to the json file
          </li>
        </ol>
      </div>
    </div>
  );
}
