import axios from "axios";
import {
  bufferToAudioBlob,
  concatenate,
  getAudioProcessor,
  getContext,
} from "lib/audio";
import "./Recording.css";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineRefresh } from "react-icons/md";

// const endpoint = "http://localhost:5000";
const endpoint = "https://recording.databank-stt-grpc.com";
export default function Recording() {
  const [recording, setRecording] = useState<boolean>(false);
  const [gldiyTransctipt, setGlidyTranscript] = useState<boolean>(true);
  const [transcript, setTranscript] = useState<string>();
  const [saveAlert, setSaveAlert] = useState<boolean>(false);
  const fetchTranscript = async () => {
    var url;
    if (gldiyTransctipt) {
      url = endpoint + "/transcript-glidy";
    } else {
      url = endpoint + "/transcript-general";
    }
    const data = await axios.get(url).then((res) => {
      return res.data;
    });
    if (data) {
      setTranscript(data);
    }
  };

  useEffect(() => {
    fetchTranscript();
  }, []);

  const processor = useRef<ScriptProcessorNode | null>(null);
  const stream = useRef<MediaStream | null>(null);
  const totalAudioBuffer = useRef<Float32Array>(Float32Array.of());

  const startRecording = async () => {
    setRecording(true);
    totalAudioBuffer.current = Float32Array.of();

    // setRecordFinished(false);

    const onAudioProcess = (event: any) => {
      const data = event.inputBuffer.getChannelData(0);
      totalAudioBuffer.current = concatenate(
        Float32Array,
        totalAudioBuffer.current,
        data
      );
    };

    const onMicrophoneCaptured = (stream: MediaStream) => {
      const context = getContext();
      const source = context.createMediaStreamSource(stream);
      processor.current = getAudioProcessor(context);
      source.connect(processor.current!);
      processor.current!.connect(context.destination);
      processor.current!.onaudioprocess = onAudioProcess;
    };

    const onMicrophoneError = (err: any) => {
      console.error(err);
    };

    stream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    try {
      onMicrophoneCaptured(stream.current);
    } catch (err) {
      console.error(err);
    }
  };

  const stopRecording = async () => {
    // source.current!.disconnect();
    processor.current!.onaudioprocess = null;
    processor.current!.disconnect();
    stream.current!.getTracks().forEach((track) => {
      track.stop();
    });
    setRecording(false);
  };

  const handleClickRecordStream = async () => {
    if (recording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const saveAudio = async () => {
    if (totalAudioBuffer.current.length < 500) {
      alert("Please submit after recording");
    }
    // let blob = bufferToAudioBlob(totalAudioBuffer.current);

    // console.log(blob.arrayBuffer);
    const res = await axios
      .post(endpoint + "/save", {
        audio: Array.prototype.slice.call(totalAudioBuffer.current),
        text: transcript as string,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        alert("오디오 저장 실패ㅠㅠ");
      });
    
      if (res != undefined) {
        setSaveAlert(true)
        setTimeout(() => {
          setSaveAlert(false)
        }, 2000);
      }
  };

  const playAudio = async () => {
    let blob = bufferToAudioBlob(totalAudioBuffer.current);
    var url = window.URL.createObjectURL(blob);
    var audio = new Audio();
    audio.src = url;
    audio.play();
  };

  return (
    <div className="main">
      <h1>Recording</h1>
      <div className="text-box">
        <select
          className=""
          style={{ height: "40px", marginTop: "auto", marginBottom: "auto" }}
          defaultValue={"Glidy용"}
          onChange={(e) => {
            if (e.target.value == "1") {
              setGlidyTranscript(true);
            } else {
              setGlidyTranscript(false);
            }
          }}
        >
          <option value="1">Glidy용</option>
          <option value="2">일반대화</option>
        </select>
        {transcript && <p className="recording-transcript">{transcript}</p>}

        <MdOutlineRefresh
          size={25}
          style={{ marginTop: "auto", marginBottom: "auto" }}
          onClick={fetchTranscript}
        />
      </div>
      <p>Glidy는 "글리디"가 아닌 "글라이디"로 발음해주세요</p>
      <div className="button-box">
        <button
          className={`btn btn-small record-btn ${
            recording ? "btn-outline-danger" : "btn-outline-primary"
          }`}
          onClick={handleClickRecordStream}
          id="record"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            color={recording ? "red" : "blue"}
            fill="currentColor"
            className="bi bi-record-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          </svg>
          {recording ? " Stop" : " Start"} Recording
        </button>
        <div>
          <button
            className={`btn btn-small ${
              recording ? "btn-light" : "btn-secondary"
            }`}
            style={{ margin: "2px" }}
            onClick={playAudio}
            disabled={recording}
          >
            Play
          </button>
          <button
            className={`btn btn-small ${
              recording ? "btn-light" : "btn-secondary"
            }`}
            style={{ margin: "2px" }}
            onClick={saveAudio}
            disabled={recording}
          >
            Submit
          </button>
          {
          saveAlert && <span style={{color: "green"}}>성공!</span>}
        </div>
      </div>
    </div>
  );
}
