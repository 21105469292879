import "bootstrap/dist/css/bootstrap.min.css";

export default function Spinner() {
  return (
    <div
      className="spinner-border"
      role="status"
      style={{ width: "1.5rem", height: "1.5rem" }}
    >
      <span className="sr-only"></span>
    </div>
  );
}
