import Guide from "components/Guide";
import "./App.css";
import Audio from "./components/Audio";
import BigAudio from "./components/BigAudio";
import StreamAudio from "components/StreamAudio";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "routes/Main";
import Recording from "routes/Recording";

export default function App() {
  return (
    <div className="App">
      {/* <h1 style={{ padding: "30px 0 0 0" }}>Databank - Speech To Text Demo</h1>
      <hr /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/recording" element={<Recording />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
