import { AudioFile } from "./types";

const SR = 16000;

// TODO: AudioWorklet으로 변경
export function getContext(): AudioContext {
  const audioCtx = new window.AudioContext({ sampleRate: SR });
  return audioCtx;
}

export function concatenate(
  resultConstructor: any,
  ...arrays: Float32Array[] | Float64Array[]
) {
  let totalLength = 0;
  for (const arr of arrays) {
    totalLength += arr.length;
  }
  const result = new resultConstructor(totalLength);
  let offset = 0;
  for (const arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
}

export function getAudioProcessor(
  audioCtx: AudioContext,
  bufferSize: number | undefined = undefined
): ScriptProcessorNode | null {
  let audioNode = null;
  if (audioCtx.createScriptProcessor) {
    audioNode = audioCtx.createScriptProcessor(bufferSize, 1, 1);
  } else {
    throw new Error("WebAudio not supported!");
  }
  return audioNode;
}

export const ReadAudio =
  (fileObj: AudioFile, fileName: React.MutableRefObject<string | null>) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];

    fileName.current = e.target.files![0].name;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const data = reader.result;
      fileObj.buffer = data as ArrayBuffer;
    };
  };

export const ReadAudioOld =
  (fileObj: AudioFile) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const data = reader.result;
      // fileObj = {buffer: data as ArrayBuffer};
      fileObj.buffer = data as ArrayBuffer;
    };
  };

export function initSocket(uri: string): WebSocket | null {
  try {
    var socket = new WebSocket(uri);
  } catch (err: any) {
    console.log("new Websocket() failed");
    return null;
  }

  socket.onerror = (err: any) => {
    console.log("web socket open failed: ", err);
  };

  return socket;
}

function writeUTFBytes(view: DataView, offset: number, string: string) {
  for (var i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

export function bufferToAudioBlob(interleaved: Float32Array): Blob {
  var buffer = new ArrayBuffer(44 + interleaved.length * 2);
  var view = new DataView(buffer);
  const sampleRate = 16000;

  // RIFF chunk descriptor
  writeUTFBytes(view, 0, "RIFF");
  view.setUint32(4, 44 + interleaved.length * 2, true);
  writeUTFBytes(view, 8, "WAVE");
  // FMT sub-chunk
  writeUTFBytes(view, 12, "fmt ");
  view.setUint32(16, 16, true); // chunkSize
  view.setUint16(20, 1, true); // wFormatTag
  view.setUint16(22, 1, true); // wChannels: mono (1 channel)
  view.setUint32(24, sampleRate, true); // dwSamplesPerSec
  view.setUint32(28, sampleRate * 4, true); // dwAvgBytesPerSec
  view.setUint16(32, 4, true); // wBlockAlign
  view.setUint16(34, 16, true); // wBitsPerSample
  // data sub-chunk
  writeUTFBytes(view, 36, "data");
  view.setUint32(40, interleaved.length * 2, true);

  // write the PCM samples
  var index = 44;
  var volume = 1;
  for (var i = 0; i < interleaved.length; i++) {
    view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
    index += 2;
  }

  // our final blob
  let blob = new Blob([view], { type: "audio/wav" });
  return blob;
}
