import Guide from "components/Guide";
import React from "react";
import Audio from "components/Audio";
import BigAudio from "components/BigAudio";
import StreamAudio from "components/StreamAudio";

export default function Main() {
  return (
    <div>
      <h1 style={{ padding: "30px 0 0 0" }}>Databank - Speech To Text Demo</h1>
      <div className="transcribe-block">
        <Guide />
        <Audio />
        <BigAudio />
        <StreamAudio />{" "}
      </div>
    </div>
  );
}
