import Spinner from "./common/Spinner";
import { ReadAudio, ReadAudioOld } from "lib/audio";
import { AudioFile } from "lib/types";
import { initSocket } from "lib/audio";
import { RecordingState } from "lib/types";
import React, { useEffect, useState, useRef } from "react";
import * as urls from "../url";
import "./component.css";
import TextComponent from "./common/TextComponent";
import DownloadButton from "./common/ResultDownloadButton";
import Hints from "./common/Hints";
import TranscribeButton from "./common/TranscribeButton";

export default function BigAudio() {
  const [loading, setLoading] = useState(false);
  const [bigFileState, setBigFileState] = useState<RecordingState>({
    recording: false,
    socket_connected: false,
  });

  const fileName = useRef<string | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const dataBuffer = useRef<AudioFile>({ buffer: new ArrayBuffer(0) });

  const [hints, setHints] = useState<string>("");

  const initSocketInside = () => {
    socket.current = initSocket(urls.WS_URI_BIGFILE);
    socket.current!.onclose = (msg: CloseEvent) => {
      setBigFileState({
        recording: false,
        socket_connected: false,
      });
      setTimeout(() => {
        initSocketInside();
      }, 1000);
    };
    socket.current!.onopen = () => {
      setBigFileState({
        recording: false,
        socket_connected: true,
      });
    };
  };

  useEffect(() => {
    initSocketInside();
  }, []);

  const AudioSend = function (event: React.MouseEvent<HTMLButtonElement>) {
    if (fileName.current == null) {
      alert("submit audio file");
      return;
    }
    if (dataBuffer.current.buffer.byteLength == 0) {
      alert("submit new audio file");
      return;
    }
    socket.current!.onmessage = function (e) {
      document.getElementById("transcriptBigFile")!.innerText = e.data;
      setLoading(false);
    };
    socket.current!.send(hints);
    const chunkLength = 20480;
    const nChunks = Math.ceil(
      dataBuffer.current.buffer.byteLength / chunkLength
    );
    let sendData;
    for (let i = 0; i < nChunks; ++i) {
      if (i != nChunks - 1) {
        sendData = dataBuffer.current.buffer.slice(
          chunkLength * i,
          chunkLength * (i + 1)
        );
      } else {
        sendData = dataBuffer.current.buffer.slice(
          chunkLength * i,
          dataBuffer.current.buffer.byteLength
        );
      }
      socket.current!.send(sendData);
    }
    socket.current!.send("end");
    setLoading(true);
  };

  return (
    <div className="container">
      <h2 className="category">
        Large Audio File Recognition (Sample Rate 16000)
      </h2>

      <div className="box">
        <div className="meta">
          <div className="file-transcirbe-button">
            {/* <label className="btn-label" htmlFor="bigAudioSubmitLabel">
              Choose File
            </label> */}
            <input
              id="bigAudioSubmitLabel"
              className="btn-upload"
              type="file"
              accept="audio/*"
              // style={{ display: "none" }}
              onChange={ReadAudio(dataBuffer.current, fileName)}
            ></input>
            <Hints setStateValue={setHints} />
            <TranscribeButton
              socketConnected={bigFileState.socket_connected}
              onClick={AudioSend}
            />
          </div>

          {loading ? <Spinner /> : <></>}
          {!loading && fileName.current && (
            <DownloadButton
              fileName={fileName.current}
              id="transcriptBigFile"
            />
          )}
        </div>
        <TextComponent id="transcriptBigFile" />
      </div>
      <hr />
    </div>
  );
}
