import React from "react";

type Props = {
  socketConnected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
export default function TranscribeButton({ socketConnected, onClick }: Props) {
  const btnClass = "Send btn btn-outline-primary btn-sm";
  const disabledBtnClass = btnClass + " disabled";
  return (
    <button
      className={socketConnected ? btnClass : disabledBtnClass}
      onClick={onClick}
    >
      Transcribe!
    </button>
  );
}
