import React from "react";

type IdProps = {
  id: string;
  message?: string;
};

export default function TextComponent({ id, message }: IdProps) {
  return (
    <div className="textbox">
      <div className="transcript-box">
        <p id={id} className="transcript-text"></p>
      </div>
      {message && <div>{message}</div>}
    </div>
  );
}
