import { ReadAudio } from "lib/audio";
import { AudioFile, TokenPair, SttResponse } from "lib/types";
import Spinner from "./common/Spinner";

import { initSocket } from "lib/audio";
import { RecordingState } from "lib/types";
import * as urls from "../url";
import React, { useEffect, useState, useRef } from "react";
import "./component.css";
import TextComponent from "./common/TextComponent";
import DownloadButton from "./common/ResultDownloadButton";
import TranscribeButton from "./common/TranscribeButton";
import Hints from "./common/Hints";

export default function Audio() {
  const [loading, setLoading] = useState(false);
  const [fileState, setFileState] = useState<RecordingState>({
    recording: false,
    socket_connected: false,
  });

  const fileName = useRef<string | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const dataBuffer = useRef<AudioFile>({ buffer: new ArrayBuffer(0) });

  const [hints, setHints] = useState<string>("");

  const initSocketInside = () => {
    socket.current = initSocket(urls.WS_URI_FILE);
    socket.current!.onclose = (msg: CloseEvent) => {
      setFileState({
        recording: false,
        socket_connected: false,
      });
      setTimeout(() => {
        initSocketInside();
      }, 1000);
    };
    socket.current!.onopen = () => {
      setFileState({
        recording: false,
        socket_connected: true,
      });
    };
  };

  useEffect(() => {
    initSocketInside();
  }, []);

  const AudioSend = async function (
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    if (fileName.current == null) {
      alert("submit audio file");
      return;
    }
    if (dataBuffer.current.buffer.byteLength === 0) {
      alert("submit new audio file");
      return;
    }
    socket.current!.onmessage = function (e) {
      let sttResponse: SttResponse = JSON.parse(e.data);
      if (sttResponse.status === 200) {
        let ret: string[] = sttResponse.confidence.map((item: TokenPair) => {
          if (item.token === "," || item.token === "." || item.token === "?") {
            return item.token;
          } else {
            if (item.prob >= 0.7) {
              return item.token;
            } else {
              return `<span class='word-color'>${item.token}</span>`;
            }
          }
        });
        document.getElementById("transcriptFile")!.innerHTML = ret
          .join(" ")
          .replaceAll(" ,", ",")
          .replaceAll(" .", ".")
          .replaceAll(" ?", "?");
      } else {
        document.getElementById("transcriptFile")!.innerHTML =
          sttResponse.transcript;
      }

      setLoading(false);
    };
    socket.current!.send(hints);
    socket.current!.send(dataBuffer.current.buffer);
    setLoading(true);
  };

  return (
    <div className="container">
      <h2 className="category">
        Audio File Recognition (Less than 1 minute, Sample Rate 16000)
      </h2>

      <div className="box">
        <div className="meta">
          <div className="file-transcirbe-button">
            {/* <label className="btn-label" htmlFor="audioSubmitLabel">
              Choose File
            </label> */}
            <input
              id="audioSubmitLabel"
              className="btn-upload"
              type="file"
              accept="audio/*"
              // style={{ display: "none" }}
              onChange={ReadAudio(dataBuffer.current, fileName)}
            ></input>

            <Hints setStateValue={setHints} />
            <TranscribeButton
              socketConnected={fileState.socket_connected}
              onClick={AudioSend}
            />
          </div>
          {loading ? <Spinner /> : <></>}
          {!loading && fileName.current && (
            <DownloadButton fileName={fileName.current} id="transcriptFile" />
          )}
        </div>
        <TextComponent
          id="transcriptFile"
          message=" * Incorrect pronunciation is marked in orange"
        />
      </div>
      <hr />
    </div>
  );
}
