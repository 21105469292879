import React from "react";

type Props = {
  setStateValue: React.Dispatch<React.SetStateAction<string>>;
};

export default function Hints({ setStateValue }: Props) {
  const handleHints = ({ target: { value } }: any) => {
    setStateValue(value);
  };

  const handleReset = () => {
    setStateValue("");
  };
  return (
    <form onChange={handleHints}>
      <div className="mb-3">
        <input
          type="hints"
          className="form-control"
          id="hintsInput"
          aria-describedby="hintHelp"
          placeholder="work, name, tell"
        />

        <div id="hintWords" className="form-text">
          Hint words {}
          <input
            type="reset"
            value="reset"
            className="btn btn-small btn-secondary refresh-btn"
            onClick={handleReset}
          />
        </div>
      </div>
    </form>
  );
}
